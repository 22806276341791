@import "../../sass/styles.scss";

.monthly-topic-layout {
  @include v-stack;
  @include vh-pad($gap-lg, $gap-xxl);
  @include h-mar($gap-ml);
  @include round;
  @include box-shadow;
  background-color: var(--bg3);
  gap: $txt-ml;
  .monthly-topic-layout__header {
    @include v-stack-c;
    gap: $gap-md;
    flex-wrap: wrap;
    justify-content: center;
    * {
      text-align: center;
    }
    .monthly-topic-layout__header__prefix {
      @include h-stack-c;
      text-transform: capitalize;
      .monthly-topic-layout__header__prefix__icon {
        @include r-pad;
        font-size: $txt-xl;
      }
    }
    .monthly-topic-layout__header__title {
      font-weight: bold;
      text-transform: capitalize;
      font-size: $txt-lg;
    }
  }
  .monthly-topic-layout__content {
    @include v-stack;
    gap: $txt-md;
  }
  .monthly-topic-layout__footer {
    @include v-stack;
    gap: $gap-xl;
    align-items: flex-end;
  }
}
