@import "../../sass/styles.scss";

.massage-therapy-view {
  .massage-therapy-view__portrait {
    @include stack;
    @include round-border;
  }
  .massage-therapy-view__card {
    @include v-mar($gap-xxl);
    @include stack;
    @include round-border;
    align-self: center;
    max-width: 500px;
    width: 100%;
    height: auto;
  }
  .massage-therapy-view__motto {
    @include stack;
    @include text-gradient(var(--secondary2), var(--secondary));
    text-shadow: 0px 0px 2px #0aa5ff69;
    width: 100%;
    justify-content: center;
    letter-spacing: $gap-sm;
    text-transform: lowercase;
    &,
    * {
      font-size: $txt-lg;
    }
  }
  .massage-therapy-view__link {
    @include vh-pad($gap-md, $gap-xl);
    @include round;
    width: fit-content;
    color: var(--bg1);
    @include h-center;
    background-color: var(--primary);
    &:hover {
      background-color: var(--secondary2);
    }
  }
  .massage-therapy-view__name {
    @include v-pad($gap-xl);
    text-align: right;
  }
}
