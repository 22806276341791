@import "./variables.scss";
@import "./mixins.scss";
* {
  font-family: $font1;
  font-weight: 450;
  text-decoration: none;
  font-size: $txt-ml;
  color: var(--txt);
  border-color: var(--border);
  outline-width: $border-sm;
  outline-color: var(--outline);
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  border-style: none;
  transition-property: color, background-color;
  transition-duration: 0.25s;
}
html,
body,
#root {
  width: 100vw;
  height: 100%;
  min-height: calc(100vh - $navbar-height);
  height: calc(100vh - $navbar-height);
}
.error {
  color: var(--danger);
  font-weight: bold;
}
a {
  @include link;
}
button {
  @include btn;
}
input,
textarea,
select {
  background-color: var(--bg3);
  color: var(--txt);
}
select {
  height: 24px;
}

hr {
  background-color: var(--border);
  border-radius: $radius-md;
  height: $gap-xs;
}
blockquote {
  text-align: center;
  font-weight: bold;
}
b {
  font-weight: bold;
}
i {
  font-style: italic;
}
