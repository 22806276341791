/* font */

/* palette */

:root {
  --bg1: hsl(36, 17%, 92%);
  --bg2: hsl(36, 17%, 90%);
  --bg3: hsl(36, 17%, 88%);
  --bg4: hsl(36, 17%, 86%);
  --mask-dark: #00000033;
  --mask-light: #00000099;
  --bg1trans: #f7f7f730;
  --bg2trans: #efefeff8;
  --border: #184b2c;
  --outline: #87c5ff;
  --txt: #313131;
  --txt-light: var(--bg1);
  // --txt: #f8f8f8;
  --neg-txt: #e4e4e4;
  --primary: #0b3a18;
  --primary2: #144633;
  --secondary: #421f58;
  --secondary2: #5d2b7c;
  --primary-gradient: linear-gradient(90deg, var(--primary) 0%, var(--primary2) 60%, var(--primary2) 100%);
  --secondary-gradient: linear-gradient(90deg, var(--secondary) 0%,  var(--secondary2) 60%, var(--secondary2) 100%);
  --mixed-gradient: linear-gradient(90deg, var(--primary) 0%, var(--primary2) 60%, var(--secondary2) 90%, var(--secondary) 100%);
  --highlight: #864da8;
  --danger: #e55454;
  --danger-hl: #bf4928;
  --bg-hl: #f7f7f7;
  --bg-sel: #d8d8d8;
  --inactive: #6b6b6b;
  --glow: #fff4a377;
}

/* fonts */
$font1: "Nunito", "Raleway", "Arial", sans-serif;
$font2: "Cinzel", serif;
$font3: "Marcellus SC", serif;

/* sizes */
$gap-xs: 1px;
$gap-sm: 2.5px;
$gap-md: 5px;
$gap-ml: 7px;
$gap-lg: 10px;
$gap-xl: 15px;
$gap-xxl: 25px;

$txt-xs: 8px;
$txt-sm: 10px;
$txt-ms: 13px;
$txt-md: 15px;
$txt-ml: 18px;
$txt-lg: 24px;
$txt-xl: 30px;
$txt-xxl: 40px;

$border-sm: 1px;
$border-md: 1.5px;
$border-ml: 3px;
$border-lg: 5px;
$border-xl: 10px;

$radius-sm: 2.5px;
$radius-md: 5px;
$radius-lg: 10px;

$grow: 1.025;

$work-img-size: 225px;

$navbar-height: 80px;
