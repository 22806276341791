$slide-delta: 30px;

@-webkit-keyframes slide-up {
  from {
    translate: 0px $slide-delta;
    opacity: 0%;
  }
  to {
    translate: 0px 0px;
    opacity: 100%;
  }
}

@keyframes slide-up {
  from {
    translate: 0px $slide-delta;
    opacity: 0%;
  }
  to {
    translate: 0px 0px;
    opacity: 100%;
  }
}

@-webkit-keyframes slide-down {
  from {
    translate: 0px calc($slide-delta * -1);
    opacity: 0%;
  }
  to {
    translate: 0px 0px;
    opacity: 100%;
  }
}

@keyframes slide-down {
  from {
    translate: 0px calc($slide-delta * -1);
    opacity: 0%;
  }
  to {
    translate: 0px 0px;
    opacity: 100%;
  }
}

@-webkit-keyframes slide-left {
  from {
    translate: $slide-delta 0px;
    opacity: 0%;
  }
  to {
    translate: 0px 0px;
    opacity: 100%;
  }
}

@keyframes slide-left {
  from {
    translate: $slide-delta 0px;
    opacity: 0%;
  }
  to {
    translate: 0px 0px;
    opacity: 100%;
  }
}

@-webkit-keyframes slide-right {
  from {
    translate: calc($slide-delta * -1) 0px;
    opacity: 0%;
  }
  to {
    translate: 0px 0px;
    opacity: 100%;
  }
}

@keyframes slide-right {
  from {
    translate: calc($slide-delta * -1) 0px;
    opacity: 0%;
  }
  to {
    translate: 0px 0px;
    opacity: 100%;
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@-webkit-keyframes drop-down {
  from {
    overflow: hidden;
    height: 0px;
  }
  to {
    overflow: auto;
    height: 100%;
  }
}

@keyframes drop-down {
  from {
    overflow: hidden;
    height: 0px;
  }
  to {
    overflow: auto;
    height: 100%;
  }
}

@-webkit-keyframes rotate-in {
  from {
    rotate: -15deg;
  }
  to {
    rotate: 0deg;
  }
}

@keyframes rotate-in {
  from {
    rotate: -15deg;
  }
  to {
    rotate: 0deg;
  }
}

@-webkit-keyframes h-grow {
  from {
    width: 0px;
  }
  to {
    width: 250px;
  }
}

@keyframes h-grow {
  from {
    width: 0px;
  }
  to {
    width: 250px;
  }
}

@keyframes glow {
  from {
    // filter: drop-shadow(0px 0px 10px blue)
    text-shadow: 0px 0px 10px blue;
    // color: blue;
  }
  to {
    text-shadow: 0px 0px 10px red;
    // color: red;
  }
}
